import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Page } from '@firsttable/types';
import usePageRehydrate from '../hooks/usePageRehydrate';
import FAQLayout from '../layouts/FAQLayout';

const IntegrationFAQPageTemplate = ({
  data: { page },
}: {
  data: { page: Page };
}) => {
  const pageData = usePageRehydrate(page);

  const [faqs] = pageData.blocks.edges
    .filter(({ node: filteredFAQNode }: any) => filteredFAQNode.key === 'FAQs')
    .map(({ node }: any) => {
      const data = JSON.parse(node.value);
      return data.map((block: any) => ({
        question: block.Title,
        answer: block.Content,
      }));
    });

  return <FAQLayout pageData={pageData} faqs={faqs || []} />;
};

IntegrationFAQPageTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }).isRequired,
};
IntegrationFAQPageTemplate.defaultProps = {};

export default IntegrationFAQPageTemplate;

export const query = graphql`
  query getIntegrationFAQPage($slug: String!) {
    page: integration(slug: { eq: $slug }) {
      slug
      title
      parentId
      metaTitleFormatted
      metaDescription
      metaTags
      menuTitle
      id
      foreignId
      content
      className
      blocks {
        edges {
          node {
            id
            title
            sort
            key
            value
          }
        }
      }
    }
  }
`;
